<template>
  <div class="metadata-options">
    <v-card-actions class="pa-0">
      <page-title :title="title" />
      <v-spacer></v-spacer>
      <!-- <AddButton
        :create-function="openDialog"
        :icon="'mdi-view-grid-plus-outline'"
        :title="'ADD NEW'"
      /> -->
    </v-card-actions>

    <v-card>
      <v-data-table
        :loading="loading"
        :headers="headers"
        :items="items"
        :value="selected"
        :server-items-length="params.total"
        :options.sync="pagination"
        :items-per-page="params.size"
        class="elevation-1"
        :no-data-text="'Data not available from this resource'"
        :footer-props="{
          'items-per-page-options': [20, 50, 100, 300, 500, -1],
        }"
      >
        <template v-slot:item.isCurrent="{ item }">
          <v-switch readonly v-model="item.isCurrent"></v-switch>
        </template>

        <template v-slot:top>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-col cols="6" sm="12" md="4" class="pa-0">
              <SearchField
                :search-function="searchOptions"
                :prepend-icon="'mdi-magnify'"
                :label="'Search Data'"
                :append-icon="''"
              />
            </v-col>
          </v-card-title>
        </template>
        <template v-slot:item.actions="{ item }">
          <ActionsButtons
            :update-function="openDialog"
            :delete-function="openDeleteDialog"
            :formData="item"
            :updateIcon="' mdi-pencil-box-outline'"
            :deleteIcon="'mdi-trash-can-outline'"
            :updateButtonColor="''"
            :deleteButtonColor="''"
          />
        </template>
      </v-data-table>
      <floating-button
        :create-function="openDialog"
        :icon="'mdi-plus'"
        :title="''"
      />
    </v-card>

    <SchoolClassesDialog
      :closeDialog="closeDialog"
      :isEditing="isEditing"
      :formData="formData"
      :dialog="dialog"
      :save="save"
      :loading="loading"
    />
    <ConfirmDialog
      :reject-function="closeDeleteDialog"
      :accept-function="deleteObject"
      :item="item"
      :isOpen="isOpen"
      :title="'Are you sure you want to delete?'"
      :loading="loading"
    />
  </div>
</template>

<script>
import SchoolClassesDialog from "./Forms/AcademicYearsDialog.vue";
import { get, create, update, remove } from "./services";
//import { get as getLevels } from "../levels/services";
import { v4 as uuidv4 } from "uuid";
import PageTitle from "../shared/PageTitle.vue";

export default {
  components: {
    SchoolClassesDialog,
    PageTitle,
  },
  name: "SchoolClasses",
  data() {
    return {
      selected: [],
      title: "Manage Academic Years",
      isEditing: false,
      formData: {},
      dialog: false,
      isOpen: false,
      loading: false,
      params: {},
      item: null,
      items: [],
      levels: [],
      streams: [],
      headers: [
        {
          text: "No.",
          align: "start",
          sortable: false,
          value: "index",
          class: "primary--text",
        },
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
          class: "primary--text",
        },

        {
          text: "Description",
          align: "start",
          sortable: false,
          value: "description",
          class: "primary--text",
        },
        {
          text: "Is Current",
          align: "start",
          sortable: false,
          value: "isCurrent",
          class: "primary--text",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          class: "primary--text",
        },
      ],
    };
  },

  computed: {
    pagination: {
      get() {
        return this.params;
      },

      set(value) {
        // console.log(value)
        this.params.page = value.page;
        this.params.size =
          value.itemsPerPage != -1 ? value.itemsPerPage : this.params.total;

        this.params.total = this.params.total;

        this.init(this.params);
      },
    },
  },

  methods: {
    searchOption() {},
    init(params) {
      this.loading = true;
      get({
        ...params,
      }).then((response) => {
        this.loading = false;
        //  console.log("resp", response.data[0].total);
        this.params.total = response.data[0].total;
        this.items = response.data[0]
          ? response.data[0].data
              .map((item) => ({
                ...item,
                is_current: item.is_current === 1 ? true : false,
                isCurrent: item.is_current === 1 ? true : false,
              }))
              .sort(function(a, b) {
                if (a > b) return 1;
                return -1;
              })
              .map((item, index) => ({
                ...item,
                index: ++index,
              }))
          : [];
      });
    },

    openDialog(data = null) {
      this.dialog = true;
      if (data && data.id) {
        this.formData = data;
      } else {
        this.formData.uuid = uuidv4();
      }
    },

    closeDialog() {
      this.loading = false;
      this.dialog = false;
      this.formData = {};
      //this.finishDataActions()
    },

    closeDeleteDialog() {
      this.item = null;
      this.isOpen = false;
    },

    openDeleteDialog(item) {
      this.isOpen = true;
      this.item = item;
    },

    deleteObject() {
      this.loading = true;
      remove(this.item)
        .then(() => {
          this.finishDataActions();
        })
        .catch((err) => {
          if (err) {
            this.loading = false;
          }
        });
    },

    searchOptions(optionName) {
      this.init({
        search: optionName ? optionName : null,
        size: this.params.size,
        total: this.params.total,

        //...this.params,
      });
    },

    save(data) {
      this.loading = true;
      if (data.id) {
        update(data)
          .then(() => {
            this.finishDataActions();
          })
          .catch((err) => {
            if (err) {
              this.loading = false;
            }
          });
      } else {
        create(data)
          .then(() => {
            console.log("created");
            this.finishDataActions();
            //this.init(this.params)
          })
          .catch((err) => {
            if (err) {
              this.loading = false;
            }
          });
      }
    },
    finishDataActions() {
      this.loading = false;
      this.isOpen = false;
      this.closeDialog();
      this.init(this.params);
    },
  },

  watch: {
    pagination: {
      handler() {
        this.loading = true;
        this.init(this.params);
      },
      update() {
        this.loading = true;
        this.init(this.params);
      },
      deep: true,
    },
  },

  mounted() {},
};
</script>

<style scoped></style>
